import { Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./signUP.styles.css"
import logo from "../assets/images/Logo-new.svg"
import Carousel from '../mui-components/Carousel'
import { useForm } from "react-hook-form";
import { aguserStore, configStore, loginStore } from '../state/CommonState'
import AguserApi from '../state/AguserApi'
import OtpInput from '../mui-components/OtpInput'
import Timer from '../mui-components/Timer'
// import { useMode } from '../context/AuthContext'
import { APP_VERSION } from '../util/constants'
import UsersApis from '../state/users-api'

function VerifyEmail({callback, source}) {
  // const {setMode : callback} = useMode();
    const [OTP, setOTP] = useState("")
    const [Error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const sourceFromSignup = source === "signupOTP"
useEffect(() => {
  // loginStore.email = 'deenathaya23@gmail.com'
    if (OTP.length != 0  && OTP.length < 6) {
      setError('Enter Valid OTP ')
    }
    if (OTP.length == 6) {
      setError('')
    }
    console.log("Source: ", source);
}, [OTP])

useEffect(()=>{
  if(source !== "signupOTP" && source !== "loginOTP"){  
  setError(source)
  }
},[])


const getOTP =async (data)=>{
  if (sourceFromSignup) {
    await AguserApi.GetOTP({ email: loginStore.email.toLowerCase(), userRec: aguserStore.aguserRec }, (user) => {
      console.log(user)
      configStore.setModalMessage("OTP has been sent your email Id")
    }
    )
  } else {
    // setOTP('')
    // setError('')
    await AguserApi.GetMobileOTP({ userRec: aguserStore.aguserRec, source: "login" }, (user) => {
      console.log(user)
      setOTP("") 
      if(user.data === 'failure'){
        setError(user.message)
      }else{
        setError("") 
      }
     
      configStore.setModalMessage("OTP has been sent your phone number")
    }
    )
  }
}
const verifyOTP =async (data)=>{
  
  // await AguserApi.VerifyOTP({email:aguserStore.aguserRec.mobile,otp:OTP}, (user)=>{
  //   console.log(user);
  //   if (user.success) {
  //       loginStore.email = aguserStore?.aguserRec?.email.toLowerCase()
  //       callback("register")
  //   }
  //    configStore.setModalMessage(user.msg);
  //   }
  // )
  setLoading(true)
  if (OTP.length < 6 ) {
    setLoading(false)
    // configStore.setModalMessage("Enter Valid OTP");
    return ""
  }
  if(sourceFromSignup){
       await AguserApi.NEWregisterNewUser({OTP},(data)=>{
        console.log(data);
        if (!data.success) {
          setLoading(false)
          setError(data.msg)
         return configStore.setModalMessage(data.msg);
        } else {
          console.log("data",data);
          setError('')
          localStorage.setItem('ag_email', data?.user?.aguserRec?.email)
          loginStore.email = data?.user?.aguserRec?.email;
          loginStore.isShowClientDashBoard = data?.user?.aguserRec?.role
          loginStore.userRec.aguserRec = data.user?.aguserRec
          const userRecString = JSON.stringify(data.user)
          localStorage.setItem("ag_remember_user",true);
          localStorage.setItem('ag_userrec', userRecString)
          localStorage.setItem('devicetoken', data.user?.aguserRec.token)
          localStorage.setItem('tour', data.user?.aguserRec.tour)
          localStorage.setItem('version', APP_VERSION )
          // callback('entity');  
          // callback('login');  
          configStore.setModalMessage("You are successfully signed up");
          let userRec = aguserStore.aguserRec
          let datas = {
            email:userRec?.email,
          password:userRec?.password,
          rememberMe : true
          }
         setUserloginData(datas)
        
        }

       }
     )
    } else {
      console.log("aguser verify mobile OTP: ", aguserStore.aguserRec);
    await AguserApi.VerifyMobileOTP({email:aguserStore.aguserRec.email,otp:OTP,phone:aguserStore.aguserRec.phone,phonePrefix:aguserStore.aguserRec.phonePrefix}, async (user) => {
      console.log(user)
      if (user.success) {
        const data = {
          "email": aguserStore?.aguserRec?.email,
          "password": "",
          "rememberMe": true,
          "mobile": aguserStore?.aguserRec?.phone,
          "code": aguserStore?.aguserRec?.phonePrefix
        }
        console.log("data: ", data)
        await UsersApis.loginUser(data, (userRec) => {

          if (!userRec?.userFound) {
            return configStore.setModalMessage("Invalid username/password")
          }
          if (userRec?.userFound && userRec.entityList.length === 0 && userRec?.noEntity) {
            console.log("Aguser store before: ", aguserStore.aguserRec)
            aguserStore.aguserRec = userRec
            console.log("Aguser store after: ", aguserStore.aguserRec)
            localStorage.setItem('ag_email', userRec?.aguserRec?.email)
            localStorage.setItem('version', APP_VERSION)
            localStorage.setItem('tour', userRec?.aguserRec?.tour)
            localStorage.setItem('devicetoken', userRec?.aguserRec?.token)
            loginStore.isShowClientDashBoard = userRec?.aguserRec?.role
            loginStore.userRec.aguserRec = userRec?.aguserRec
            loginStore.email = userRec?.aguserRec?.email
            const userRecString = JSON.stringify(userRec)
            if (data.rememberMe) {
              localStorage.setItem("ag_remember_user", true)
              localStorage.setItem('ag_userrec', userRecString)
            } else {
              localStorage.setItem("ag_remember_user", false)
              // sessionStorage.setItem('ag_userrec', userRecString)
            }
            return callback("entity")
          }
          console.log("Aguser store before 2: ", aguserStore.aguserRec)
          aguserStore.aguserRec = userRec.aguserRec
          console.log("Aguser store after 2: ", aguserStore.aguserRec)
          loginStore.isShowClientDashBoard = userRec?.aguserRec?.role.some(role => role
            .toLowerCase()
            .startsWith("client"))
          if (userRec?.aguserRec?.token && userRec?.aguserRec?.token.length > 0) {
            localStorage.setItem('devicetoken', userRec.aguserRec.token)
            if (userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length > 0) {
              for (let i = 0;i < userRec.companyList.length;i++) {
                for (let r = 0;r < userRec.companyList[i].branches.length;r++) {
                  if (userRec.companyList[i]?.branches[r]?.gst == userRec.aguserRec.selectgst) {
                    localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
                    localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i].branches[r]))
                    configStore.currentSelectedEntity = userRec.companyList[i]
                    configStore.currentSelectedBranch = userRec.companyList[i].branches[r]
                  }
                }

              }
            } else {
              if (userRec.companyList.length > 0) {
                localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[0]))
                localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[0].branches[0]))
                configStore.currentSelectedEntity = userRec.companyList[0]
                configStore.currentSelectedBranch = userRec.companyList[0].branches[0]
              }

            }
            // localStorage.setItem('ag_current_entity', JSON.stringify(userRec.[i]))
            // localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i]))
            if (userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length > 0) {
              localStorage.setItem('tour', userRec.aguserRec.tour)
            }
            localStorage.setItem('ag_email', data.email)
            localStorage.setItem('version', APP_VERSION)
            // const rememberMe = localStorage.getItem('ag_remember_user')
            // if (rememberMe === 'REMEMBER') {
            //     const userRecString = JSON.stringify(userRec)
            //     localStorage.setItem('ag_userrec', userRecString)
            // }
            const userRecString = JSON.stringify(userRec)
            if (data.rememberMe) {
              localStorage.setItem("ag_remember_user", true)
              localStorage.setItem('ag_userrec', userRecString)
            } else {
              localStorage.setItem("ag_remember_user", false)
              // sessionStorage.setItem('ag_userrec', userRecString)
            }
          }
        })
      } else {
        setLoading(false)
        setError(user.msg)
        return configStore.setModalMessage(user.msg)
      }
      // configStore.setModalMessage(user.msg)
    }
    )
    
  
    }
}

async function setUserloginData (data) {
  await UsersApis.loginUser(data , (userRec)=> {
    console.log(userRec);
    if(!userRec?.userFound){
      return  configStore.setModalMessage("Invalid username/password")
    }
    if(userRec?.userFound && userRec.entityList.length === 0 && userRec?.noEntity){
      localStorage.setItem('ag_email', data.email)
      localStorage.setItem('version', APP_VERSION )
      localStorage.setItem('tour', userRec?.aguserRec?.tour)
      localStorage.setItem('devicetoken', userRec?.aguserRec?.token)
      loginStore.isShowClientDashBoard = userRec?.aguserRec?.role.some(role => role
        .toLowerCase()
      .startsWith("client"))
      loginStore.userRec.aguserRec = userRec?.aguserRec
      loginStore.email = data.email; 
      const userRecString = JSON.stringify(userRec)
      if (data.rememberMe) {
      localStorage.setItem("ag_remember_user",true);
      localStorage.setItem('ag_userrec', userRecString)
      }else{
      localStorage.setItem("ag_remember_user",false);
      configStore.currentSelectedEntity= {
        entityName : '',
        entityId : '',
        branches: [],
      }
      configStore.currentSelectedBranch= {
        branchId : ''
      }
    // sessionStorage.setItem('ag_userrec', userRecString)
     }
    // return  callback("entity")
    
    }
    else {
      loginStore.isShowClientDashBoard = userRec?.aguserRec?.role.some(role => role
        .toLowerCase()
      .startsWith("client"));  
      if(userRec?.aguserRec?.token && userRec?.aguserRec?.token.length>0){
                  localStorage.setItem('devicetoken', userRec.aguserRec.token)
                  if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
                      for (let i = 0; i < userRec.companyList.length; i++) {
                        for(let r = 0 ; r < userRec.companyList[i].branches.length; r++ ){
                          if(userRec.companyList[i]?.branches[r]?.gst == userRec.aguserRec.selectgst){
                            localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
                            localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i].branches[r]))
                    configStore.currentSelectedEntity=userRec.companyList[i]
                    configStore.currentSelectedBranch=userRec.companyList[i].branches[r]
                        }
                        }
                         
                      }
                  }else {
                    if(userRec.companyList.length > 0 ) {
                      localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[0]))
                      localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[0].branches[0]))
                      configStore.currentSelectedEntity=userRec.companyList[0]
                      configStore.currentSelectedBranch=userRec.companyList[0].branches[0]
                    }
  
                  }
                  // localStorage.setItem('ag_current_entity', JSON.stringify(userRec.[i]))
                  // localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i]))
                  if(userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length>0){
                      localStorage.setItem('tour', userRec.aguserRec.tour)
                  }
                  localStorage.setItem('ag_email', data.email)
                  localStorage.setItem('version', APP_VERSION )
                  // const rememberMe = localStorage.getItem('ag_remember_user')
                  // if (rememberMe === 'REMEMBER') {
                  //     const userRecString = JSON.stringify(userRec)
                  //     localStorage.setItem('ag_userrec', userRecString)
                  // }
                  const userRecString = JSON.stringify(userRec)
                  if (data.rememberMe) {
                  localStorage.setItem("ag_remember_user",true);
                  localStorage.setItem('ag_userrec', userRecString)
              }else{
                localStorage.setItem("ag_remember_user",false);
                // sessionStorage.setItem('ag_userrec', userRecString)
              }
              }

    }
    
           
})
}

const delivery =sourceFromSignup ? " " + loginStore?.email : " " + aguserStore?.aguserRec?.phonePrefix + " " + aguserStore?.aguserRec?.phone
const buttonText = sourceFromSignup ? "Sign Up" : "Submit"

const handleGoBack = (isSourceFromSignup, callback, loginStore) => {
  const action = isSourceFromSignup ? 'register' : 'login';
  callback(action);
  loginStore.email = '';
};

  return (
    <>
      <div className='signIN_container'>
        <div className='info_container'>
          <div className='logo_container'>
            <img src={logo}></img>
          </div>
          <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
            with ease.</Typography>
          <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
          <Carousel />
        </div>
        <div className='field_container'>
        <div className='logo_container_for_mobile'>
         <img src={logo}></img>
        </div>
          <div className='field_wrapper verify_for_mobile'>
            <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={"1rem"} gutterBottom>OTP Verification</Typography>

              <Typography variant="h6" fontFamily={"Figtree, sans-serif"} fontSize={"12px"} fontWeight={400} color={"#555"} marginBottom={"1rem"} gutterBottom>
                        {/*OTP has been sent to your phone <br />
                        <span style={{ color: '#2a6ed4' }}>{aguserStore.aguserRec.phonePrefix} {aguserStore.aguserRec.phone}</span> via SMS and WhatsApp}*/}
                        </Typography>
       
            {/* <Button variant="contained" className='btn_color' fullWidth  onClick={getOTP} >GET OTP</Button> */}
            <OtpInput length={6} callback={(e) => {
              setOTP(e)
              console.log(e)
            }} errorsmsg={Error} />
            <div className='mb-4 is-flex is-justify-content-space-between is-align-items-center' style={{ fontSize: '12px' }}>
              <p>Didn't get it?</p>
              {/* <p className='fogot_password clr_blue' onClick={getOTP} >Resend Code</p> */}
              <Timer seconds={30} minutes={0} resend={() => getOTP()} ></Timer>
            </div>

            <Button variant="contained" disabled={loading} className='btn_color' fullWidth onClick={verifyOTP} >{buttonText}</Button>

          </div>

          <p className='note_info'>Wrong email address? <span className='clr_blue' onClick={() => handleGoBack(sourceFromSignup, callback, loginStore)}>Go Back</span></p>
        </div>

      </div>
    
    </>
  )
}


export default VerifyEmail